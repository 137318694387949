@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
*,
*::before,
*::after,
p {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
:root {
  --white: #ffffff;
  --black: #010103;
  --blue: #f2f6ff;
  --skyblue: #3e7bfa;
  --ofgrey: #a5a8a7;
  --danger: #f01d10;
  --bgcolor: #e3e3e3;
  --border: #d9d9d9;
  --statpink: #fb5d80;
  --statblue: #3cb3d8;
  --green: #3cd856;
}
*:focus {
  outline: -webkit-focus-ring-color none;
  outline: none;
}
body {
  line-height: 1;
  overflow-x: hidden;
  font-weight: 400;
  font-family: "Nunito" !important;
}
a {
  text-decoration: none;
}
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input:focus {
  box-shadow: none !important;
}
::-webkit-scrollbar {
  width: 0px;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0 !important;
  font-family: "Nunito" !important;
}
.row {
  --bs-gutter-x: 0.75rem !important;
  --bs-gutter-y: 0.75rem !important;
}
.title1 {
  font-size: 44px;
  font-weight: 700;
}
.title2 {
  font-size: 32px;
  font-weight: 700;
}
.title3 {
  font-size: 25px;
  font-weight: 700;
}
.title4 {
  font-size: 22px;
  font-weight: 500;
}
.title5 {
  font-size: 18px;
  font-weight: 500;
}
p,
label,
a {
  font-size: 16px;
}
select {
  font-size: 16px !important;
}
.span1 {
  font-size: 14px;
}
.span2 {
  color: var(--ofgrey);
  font-size: 12px;
}
.btn {
  font-size: 18px;
  height: 45px;
  padding-inline: 20px !important;
}
/*   LogIn  */
.rgtside-login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--blue);
}
.login-form-holder {
  display: flex;
  align-items: center;
  min-height: 95vh;
  margin: 10px;
  /* padding: 10% 15%; */
  overflow-y: auto;
  box-sizing: border-box;
  background: #f8fdff;
}
.form-holder {
  display: flex;
  align-items: center;
  height: auto;
  margin: 10px;
  /* padding: 10% 15%; */
  overflow-y: auto;
  box-sizing: border-box;
  background: #f8fdff;
}
.logo {
  margin-top: 30px !important;
  max-width: 150px;
  height: 150px;
  /* margin: 0 auto; */
}
form label {
  font-weight: 600;
  margin-bottom: 5px;
}
form .form-control,
select,
.multi-select .css-13cymwt-control {
  min-height: 45px;
  color: #959595;
}
.err-msg {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.multi-select {
  z-index: 999;
}
form .form-control:focus {
  box-shadow: none;
}
.logIn-form{
  width: 100%;
  padding: 0 20% !important;
}
.logIn-form .btn {
  width: 100%;
  margin-inline: auto;
  display: block;
}
.logIn-form .bottom-sec {
  width: 85%;
  margin-inline: auto;
}
.logIn-form .bottom-sec p {
  text-align: center;
}
.logIn-form .bottom-sec p.copy-right {
  color: var(--skyblue);
}
.logIn-form .bottom-sec p.copy-right span {
  letter-spacing: 2px;
}
.dashboard .sidebar .sidebar-item:nth-last-child(){
  color: red !important;
}
/*----------------------dashboard-------------------*/
.dashboard {
  background: var(--blue);
  /* gap: 0.75rem; */
}
.grey-background{
  background-color: grey;
  border-radius: 50%;
}
.dashboard .sidebar {
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.logout-btn{
  border: none;
  outline: none;
  text-align: center;
  color: red;
  font-size: 18px !important;
  background-color: transparent;
}
.logout{
  color: rgba(255, 0, 0, 0.883) !important;
  /* background-color: red !important; */
}
.sidebar .logo {
  max-width: 144px;
  height: max-content;
}
.dashboard .sidebar .sidebar-item {
  margin-block: 5px;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.dashboard .sidebar .sidebar-item svg {
  font-size: 20px;
  min-width: 20px;
}
.dashboard .sidebar .sidebar-item{
  color: black;
}
.dashboard .sidebar .sidebar-item.active,
.dashboard .sidebar .sidebar-item:hover {
  background: var(--blue);
  color: #3E7BFA;
}

.sidebar-icon {
  filter: grayscale(1);
}
.sidebar-icon .logout{
  color: red;
  background-color: red !important;
}
.logout .svg{
  color: red;
  background-color: red !important;
}
.sidebar-icon.active {
  filter: grayscale(0);
}
.dashboard .sidebar .logout-button > .sidebar-item {
  color: var(--danger);
  opacity: 0.7;
}
.dashboard .sidebar .logout-button > .sidebar-item:hover,
.dashboard .sidebar .logout-button > .sidebar-item:active {
  background: none;
  color: var(--danger);
  opacity: 1;
}
.dashboard .right-sidebar {
  width: calc(100vw - 250px);
  height: 100vh;
}
.right-sidebar .topbar {
  height: 70px;
  /* background: #ffffff; */
  /* box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08); */
  border-radius: 0px 0px 0px 20px;
}
.right-sidebar .topbar .user-prof,
.right-sidebar .topbar .notification,
.right-sidebar .topbar .search-icon,
.edit,
.delete {
  cursor: pointer;
}
.right-sidebar .topbar .topbar-title svg {
  width: 2.25rem !important;
  height: 2.25rem !important;
  color: black;
}
.right-sidebar .topbar .topbar-title{
  color: black;
}

.dashboard .right-sidebar h4 {
  /* color: var(--skyblue); */
  color: black;
}

.search-icon,
.notification {
  width: 40px;
  height: 40px;
  font-size: 25px;
  color: var(--skyblue);
  background: rgba(65, 118, 144, 0.15);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar {
  background: rgba(65, 118, 144, 0.15);
  border-radius: 12px;
  height: 40px;
}
.search-input {
  border: none;
  outline: none;
  float: left;
  background: none;
  font-size: 12px;
  width: 0px;
  height: 40px;
  transition: 0.3s;
}
.search-bar:hover > .search-input {
  padding: 10px 15px;
  width: 240px;
}
.search-bar:hover > .search-icon,
.search-input:focus + .search-icon {
  background: none;
}
.search-input:focus {
  width: 240px;
  padding: 10px 15px;
}
.right-sidebar .dashboard-content {
  height: calc(100vh - 70px);
  padding-block: 10px;
  overflow-y: auto;
  box-sizing: border-box;
}
.box-item {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 15px;
}
.subject-dropdown .form-select{
  border: none !important;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;

}

.chart-box{
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}
.red-button {
  color: red;
}

.blue-button {
  color: blue;
}
/*----------------Statistics--------------*/
.stat-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background: var(--statpink);
  color: var(--white);
  border-radius: 50%;
}
.stat2 .stat-icon {
  background: var(--green);
}
.stat3 .stat-icon {
  background: var(--statblue);
}
/*-----Student Performance----*/
.student-performance .form-select {
  width: max-content !important;
}
.form-select:focus {
  box-shadow: none !important;
}
.performance-detail {
  height: 339px;
  overflow-y: auto;
}
.studend-profile {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  color: white;
  border-radius: 50%;
}
.studend-profile img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
/*--------comments----------------*/
.comments .studend-profile {
  width: 30px;
  height: 30px;
}

.comment-list {
  height: 400px;
  overflow-y: auto;
}
.comment {
  border-bottom: 0.5px solid #a5a8a78c;
}
.comments img {
  width: 30px;
  height: 30px;
}
/*------------------Recent Activity-------------*/
.activity-timeline {
  height: 400px;
  overflow-y: auto;
}
.activity {
  position: relative;
  margin-left: 50px;
  margin-bottom: 10px;
}
.activity-img{
  width: 40px;
  height: 40px;
}
/* .activity::before {
  content: "";
  width: 35px;
  height: 35px;
  background: var(--ofgrey);
  border-radius: 50%;
  position: absolute;
  left: -50px;
  top: 0;
}
.activity-pannel::before {
  content: "";
  width: 2px;
  height: 50%;
  background: var(--ofgrey);
  position: absolute;
  top: 40px;
  left: -33px;
} */

/*----------------Subject------------------*/
.subjects {
  position: relative;
  height: 315px;
}
.subject {
  background: #f5f8f9;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 12px;
  margin-inline: 8px;
}
.subject-card-img {
  height: 140px;
}
.subject-card-img img {
  width: 100%;
  height: 100%;
}
.subject .card-subtitle {
  color: var(--ofgrey);
}
.subject .slick-list {
  height: 240px !important;
}
.slick-prev {
  left: 90% !important;
}
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}
.slick-prev,
.slick-next {
  top: -25px !important;
  right: 0px !important;
}

/*--------------------------Student------------------*/
.students .student-list {
  height: 240px;
  overflow-y: auto;
}

.select-subject-msg{
  font-style: italic;
  font-weight: bold;
  color: #3e7bfa;
}
/*--------------------------Semester List Table---------------------*/
.selectSubject .mb-3 > div {
  z-index: 999;
}
.data-table-container {
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 20px 10px;
}
.data_table .eXafXr{
  max-height: 100%;
  overflow-y: auto;
}
.data_table .rdt_TableHeadRow {
  /* background: var(--blue); */
  color: black;
  font-weight: bold;
  padding-right: 30px;
}
.rdt_TableRow {
  padding-right: 30px;
}
.data_table .rdt_TableBody {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden !important;
}
/* .data_table .rdt_TableBody > div:nth-child(even) {
  background: #ecf2ff !important;
}
 */

 .hhdiIe:hover {
  background-color: transparent !important;
  /* box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.1); */

 }
 .data-table-container .rdt_TableRow:hover {
  background: #faf8f8 !important;
  cursor: pointer;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3), 0 -5px 5px -5px rgba(0, 0, 0, 0.3);

}
.data_table .edit svg {
  color: #78A9ED;
}
.data_table .rdt_TableBody > div {
  min-height: auto !important;
  padding-block: 10px !important;
}
.data_table .rdt_TableBody > div > div {
  word-break: normal;
}
.delete svg {
  color: var(--danger);
}
.table-cell-img {
  display: block;
  width: 135px;
  height: 80px;
}
.table-cell-img img {
  width: 100%;
  height: 100%;
}
.comment-table .hQFbmv div {
  white-space: pre-wrap !important;
}
.recharts-wrapper {
  width: 100% !important;
  height: 300px !important;
}
.recharts-wrapper > svg {
  width: 100% !important;
}
/*---------------------Subjects--------------------*/
.image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc; /* Grey background color */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-upload {
  text-align: center;
  background: rgba(217, 217, 217, 0.2);
  border-radius: 12px;
  color: var(--ofgrey);
  width: 200px;
  margin:auto
}
.image-upload label {
  cursor: pointer;
}
.input-type-file input {
  border: 0;
}
.image-upload > input {
  display: none;
}
/*---------------------------Report--------------------------*/
/*--report-siderbar--*/
.report .sidebars,
.report .middle {
  height: calc(100vh - 90px);
  overflow-y: auto;
}
.report .sidebars .student-detail {
  padding: 5px 10px;
}
.report .sidebars .student-detail:hover {
  background: rgba(62, 123, 250, 0.2);
}
.report .sidebars .student-detail.active {
  background: rgba(62, 123, 250, 0.2);
}
.report .sidebar .form-select {
  height: 40px;
}
.report .sidebars .studend-profile {
  width: 50px;
  height: 50px;
}
.report .report-chart {
  height: 430px;
  
}
.report .sidebars .comment {
  border-bottom: 1px solid var(--border);
}
.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}
.accordion-button::after {
  --bs-accordion-btn-icon-width: 0.85rem;
}
.accordion-button {
  font-size: 18px;
}
.accordion-body {
  padding: 0px 10px !important;
}
.student-accordian  .accordion-body{
  max-height: 400px !important;
  overflow-y: auto !important;
}
.accordion-button {
  padding: 10px !important;
}
.accordion-button:not(.collapsed) {
  color: var(--black) !important;
}
.accordion-button:not(.collapsed)::after {
  --bs-accordion-btn-icon-width: 0.85rem !important;
  background-image: var(--bs-accordion-btn-icon) !important;
}
.accordion-button svg {
  font-size: 30px;
  margin-right: 10px;
  color: var(--skyblue) !important;
}
.report-chart {
  margin-bottom: 5rem;
  padding: relative;
}
.report-chart .recharts-legend-wrapper {
  bottom: -40% !important;
}
/*----add-comment----*/
.add-comment p.shape {
  width: 25px;
  height: 12px;
  /* background: #7c5caf; */
  border-radius: 14px;
}
.add-comment textarea {
  resize: none;
  border: 1px solid #3e7bfa;
  border-radius: 8.54019px;
}
.add-comment textarea:focus {
  box-shadow: none;
}
.comment-page textarea.form-control {
  min-height: 200px;
  resize: none;
}
.comment-page .table > :not(caption) > * > * {
  border-bottom: none;
}
.modal.show .modal-dialog {
  max-width: 850px;
 margin-top: 100px;
}

.bg-f2f6ff{
  background-color: #f2f6ff !important;
}

.close-icon svg {
  width: 30px;
  height: 30px;
}
#RFS-StepMain button span {
  color: var(--black);
  font-weight: bold;
}
#RFS-StepMain button.active {
  background-color: var(--skyblue) !important;
}
#RFS-StepMain button.active span,
#RFS-StepMain button.completed span {
  color: var(--white) !important;
}
#RFS-StepMain button.completed {
  background-color: var(--blue) !important;
}
.StepperContainer-0-2-1 {
  width: 100%;
  overflow-x: auto;
}
.StepMain-0-2-3 {
  min-width: 115px;
}
.report-select select {
  height: 40px;
  border: 1px solid #3e7bfa;
  border-radius: 8px;
}
/*-------------------Profile Page-------------*/
.profile .profile-banner {
  height: 200px;
}
.profile .profile-banner img {
  width: 100% !important;
  height: 100% !important;
}
.profile .user-info {
  height: calc(100vh - 280px);
}
.user-data {
  margin-top: -60px;
}
.profile .user-profile {
  width: 120px;
  height: 110px;
}
.profile .student-list {
  height: calc(100vh - 479px);
  overflow-y: auto;
  background: rgba(62, 123, 250, 0.05);
  box-sizing: border-box;
  border-radius: 12px 12px 12px 0px;
  padding: 15px;
}
.profile .stats {
  margin-top: -40px;
}
.stats p.title4 {
  font-size: 18px;
}
.stats h5.title1 {
  margin-block: 0px;
  font-size: 30px;
}
.profile .subject-card .row {
  height: calc(100vh - 403px);
  overflow-y: auto;
}
.stats .stat-icon {
  width: 35px;
  height: 35px;
  font-size: 20px;
}
/*---------------------------------Delete Modal---------------------------*/
.delet-icon {
  width: 150px;
  height: 150px;
  background: #ffc3bf;
  border-radius: 50%;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--danger);
  margin: 0 auto;
}

/*-------------------------------Avatar in comments cards section---------------------------*/
.avatar {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  color: white;
}

.text-blue{
  color: #3E7BFA
}
.css-13cymwt-control{
  padding: 3px !important;
}

/*-------------------------------cvs -------------------------------*/
.csv-btn {
  float: right;
  color: white;
  background-color: #128826;
  width: 150px;
  height: 35px;
  border: none;
  font-weight: 500;
  border-radius: 10px;
}
.pdf-btn {
  float: right;
  color: white;
  background-color: #128826;
  /* width: 150px; */
  padding: 0 10px;
  height: 45px;
  border: none;
  font-weight: 500;
  border-radius: 5px;
}
.print-btn {
  float: right;
  color: black;
background-color:white;
  width: max-content;
  height: 45px;
  border: none;
  padding: 0 20px;
  font-weight: 500;
  border-radius: 5px;
}

.print-btn:hover,.pdf-btn:hover{
  cursor: pointer;
  opacity: 90%;
  
}
.iKMfTY{
  min-height: 100vh !important;
}
.logout-item{
  position: absolute !important;
  bottom:0px !important;
  background-color: white !important;
}
.export-btn {
  float: right;
  color: white;
  background-color: white;
  width: 30px;
  height: 35px;
  border: none;
  font-weight: 500;
  border-radius: 10px;
}
.forget-text{
  font-size: 1rem;
  margin-top: 5px;
  color:#0692B6 ;
}
.forget-text:hover{
  cursor: pointer;
  color: blue;
}
.react-calendar__tile--active{
  border-radius: 8px !important;
}
.react-calendar{
  font-family: "Nunito" !important;
width:auto !important;
  border: none !important;
  padding: 10px !important;
  border-radius: 16px !important;
}
.react-calendar__tile--now {
  background: #3E7BFA !important;
  color: white;
  border-radius: 8px !important;
}
abbr[title]{
  text-decoration: none !important;
  text-transform: capitalize !important;
}
.react-calendar__month-view__days__day--weekend {
  color:black !important;
}
.react-calendar__navigation__prev-8-button{
  font-size: 20px !important;
}
.react-calendar__navigation__label{
  font-size: 30px ;
}
.react-calendar__navigation button{
  font-size: 20px;
}
/* .react-calendar__tile abbr {
  align-items: center;
  display: block;
  display: flex;
  height: 27px!important;
  justify-content: center;
  width: 28px!important;
  background-color:  #3E7BFA !important;
} */
.comment-modal .modal-header{
padding-right: 0 !important;
padding-left: 0 !important;}
.modal-header{
  border-bottom: none !important;
}
.border-12{
  border-radius: 35px !important;
}
.border-20{
  border-radius: 12px !important;
}
.width-200 {
  width: 200px !important;
}
.width-100 {
  width: 100% !important;
}
.width-450 {
  width: 450px !important;
}
.width-370{
  width: 370px  !important;
}
.dash-sem {
  height: 40px;
  font-size: 18px;
  font-weight: 500;
}
.students-comp .box-item {
  width: 50%;
}
.sidebar-dropdown > div {
  margin-left: 7px;
}
.sidebar-dropdown > div:nth-child(1) {
  margin-left: 0px !important;
  font-size: 16px !important;
}
/* statistics color */

/* Add these styles to your CSS file */
.stat-pink {
  color: #FE977E;
}

.stat-blue {
  color: #3CB3D8;
}

.stat-magenta {
  color:  #FB5D80;
}

.stat-green {
  color: 3CD856;
}

.text-pink {
  color: #FE977E;
}

.text-blue {
  color: #3CB3D8;
}

.text-magenta {
  color:  #FB5D80;
}

.text-green {
  color: #3CD856;
}
.cancel-btn{
  background-color: #F5F5F5 !important;
  color: black;
}
.reset-form{
  background-color: white;
  width: 50%;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);

}
.reset-form-screen{
  background-color: #f2f6ff;
}


@media only screen and (max-width: 1699px) {
  .title1 {
    font-size: 36px;
  }
  .title2 {
    font-size: 28px;
  }
  .title3 {
    font-size: 21px;
  }
  .title4 {
    font-size: 18px;
  }
  .title5 {
    font-size: 16px;
  }
  p,
  label,
  a {
    font-size: 14px;
  }
  select {
    font-size: 14px !important;
  }
  .span1 {
    font-size: 12px;
  }
  .span2 {
    font-size: 11px;
  }
  .stats p.title4 {
    font-size: 16px;
  }
  .stats h5.title1 {
    font-size: 24px;
  }
}
.borders {
  border: 0 0 1px 0 !important;
}
@media only screen and (max-width: 1399px) {
  .title1 {
    font-size: 30px;
  }
  .title2 {
    font-size: 24px;
  }
  .stats h5.title1 {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 1199px) {
  .title1 {
    font-size: 24px;
  }
  .title2 {
    font-size: 16px;
  }
  .title3 {
    font-size: 18px;
  }
  .stats p.title4 {
    font-size: 14px;
  }
  .stats h5.title1 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .dashboard .sidebar {
    padding: 15px 15px;
  }
  .dashboard .sidebar .sidebar-item {
    padding: 5px;
  }
  .profile .row.stats {
    margin-top: 0px;
  }
  .profile .subject-card .row {
    height: auto;
  }
  .profile .user-info {
    height: auto;
    padding-left: 0.45rem;
  }
  .students-comp > .box-item {
    width: 100%;
  }
  .student-section{
    
    width: 99% !important;
  }
  
  .modal.show .modal-dialog {
    max-width: 600px !important;
  }
}
